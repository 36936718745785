<template>
	<v-dialog persistent max-width="764px" ref="statusDialog" v-model="dialog">
		<v-card>
			<v-card-title class="pl-4 pr-4">
				<span class="text-h5 mb-3" v-text="getTitleText()"></span>
			</v-card-title>
			<v-card-text class="pl-4 pr-4">
                <v-form lazy-validation ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-alert dense border="left" type="warning" class="mb-0" v-html="getContentText()"></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="this.object.status===3">
                            <v-textarea rows="5" label="Ban Reason" :rules="rules.reason" :error-messages="errors.reason" v-model="object.reason"></v-textarea>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="this.object.status===4">
                            <v-textarea rows="5" label="Approve Reason" :rules="rules.reason" :error-messages="errors.reason" v-model="object.reason"></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
			</v-card-text>
			<v-card-actions class="pl-4 pr-4">
				<v-spacer></v-spacer>
				<v-btn text @click="close">Let me think about it</v-btn>
				<v-btn text color="primary" :loading="loading" @click="save">Yes, do it</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>

export default {
	name: 'AccountStatusDialog',
	data() {
		return {
			dialog: false,
			loading: false,
			valid: true,
			object: {
                id: '',
				status: -1,
				reason: ''
			},
            rules: {
                reason: [
                    (value) => !!value || 'Ban reason is required'
                ]
            },
            errors: {}
		}
	},
	computed: {
		statusDialog() {
			return this.$refs.statusDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
		},
		updateObject: function(item) {
            this.object = item
		},
		save: function() {
            if(this.form.validate()){
                this.loading = true
                const object = { id: this.object.id, object: { status: this.object.status, reason: this.object.reason } }
                this.$store.dispatch('epanel/promoter/updatePromoterStatus', object).then((response) => {
                    this.close()
                    this.$emit('updated', { object: response.data.object, message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
            }
		},
        getTitleText: function() {
            const titles = { 1: 'Lock Account', 2: 'Activate Account', 3: 'Ban Account', 4: 'Approve Account' }
            return titles[this.object.status]
        },
        getContentText: function() {
            const contents = { 1: 'Are you sure that you want to lock this account? <br>Once this account has been locked, this account cannot be used immediately.',
                               2: 'Are you sure that you want to activate this account? <br>Once this account has been activated, this account can be used immediately.',
                               3: 'Are you sure that you want to ban this account? <br>Once this account has been banned, this account cannot be used immediately.',
                               4: 'Are you sure that you want to approve this account? <br>Once this account has been approved, this account can be used immediately.'}
            return contents[this.object.status]
        }
	}
}

</script>