<template>
	<v-dialog persistent :max-width="getMaxWidth()" ref="logDialog" v-model="dialog">
		<v-card>
			<v-card-title class="pl-4 pr-4">
				<span class="text-h5 mb-3">Log History</span>
			</v-card-title>
			<v-card-text class="pl-4 pr-4">
                <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.transaction_time"></td>
                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.category"></td>
                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.remarks"></td>
                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.operator"></td>
                        </tr>
                    </template>
                </v-data-table>
			</v-card-text>
			<v-card-actions class="pl-4 pr-4">
				<v-spacer></v-spacer>
				<v-btn text @click="close">Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>

export default {
	name: 'AccountLogDialog',
	data() {
		return {
            dialog: false,
            loading: false,
            filters: {
                id: ''
            },
            items: [],
            headers: []
		}
	},
	computed: {
		logDialog() {
			return this.$refs.logDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
			this.get()
		},
		close: function() {
			this.dialog = false
			this.loading = false
		},
        updateObject: function(item) {
            this.filters = item
        },
		get: function() {
            this.loading = true
            this.$store.dispatch('epanel/promoter/getPromoterLogs', {id: this.filters.id}).then((response) => {
                this.items = response.data.items
				this.headers = response.data.headers
				this.loading = false
            }).catch(() => {
                this.loading = false
            })
		},
		getMaxWidth: function() {
            return (this.$vuetify.breakpoint.width * 0.75).toString() + "px"
		}
	}
}

</script>