<template>
	<div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Promoter Management</p>
                <p class="text-title mb-0">You do not have permission to view all promoter accounts</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field dense clearable hide-details label="Name" v-model="filters.name"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field dense clearable hide-details label="NRIC" v-model="filters.nric"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field dense clearable hide-details label="Mobile Number" v-model="filters.mobile_number"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_add">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="mt-3 elevation-0" :ripple="false" to="promoter/add" target="_blank">
                        <v-icon small left>mdi-plus</v-icon> Create Account
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0">
                        <v-tabs grow color="primary" v-model="tab" @change="changeTab">
                            <v-tab :key="index" v-text="item.text" v-for="(item, index) in statuses"></v-tab>
                        </v-tabs>
                        <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                            <template v-slot:item='{ item, index }'>
                                <tr>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.first_name"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.last_name"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.alias_name"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.nric"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.gender"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.citizenship"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.mobile_number"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.status"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                        <v-menu bottom offset-y v-if="hasMenu(item)">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn small icon v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list dense>
                                                <v-list-item :to="getViewUrl(item.id)" target="_blank" v-if="item.can_view">
                                                    <v-list-item-title>View Account</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item :to="getEditUrl(item.id)" target="_blank" v-if="item.can_edit">
                                                    <v-list-item-title>Edit Account</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openPasswordDialog(item)" v-if="item.can_edit_password">
                                                    <v-list-item-title>Change Password</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openStatusDialog(index, item, 1)" v-if="!item.is_locked && item.can_lock">
                                                    <v-list-item-title>Lock Account</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openStatusDialog(index, item, 2)" v-if="item.is_locked && item.can_lock">
                                                    <v-list-item-title>Activate Account</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openStatusDialog(index, item, 4)" v-if="item.is_rejected && item.can_approval">
                                                    <v-list-item-title>Approve Account</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openStatusDialog(index, item, 3)" v-if="!item.is_banned && item.can_ban">
                                                    <v-list-item-title>Ban Account</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="openLogDialog(item)" v-if="item.can_view_logs">
                                                    <v-list-item-title>View Logs</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="!loading && pagination.total_pages > 1">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
                </v-col>
            </v-row>
        </template>
		<password-dialog ref="passwordDialog" @updated="updateItem"></password-dialog>
		<status-dialog ref="statusDialog" @updated="updateItem"></status-dialog>
		<log-dialog ref="logDialog"></log-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import MessageNotifier from '../../../shared/MessageNotifier.vue'
import AccountPasswordDialog from '../AccountPasswordDialog.vue'
import PromoterStatusDialog from './PromoterStatusDialog.vue'
import PromoterLogDialog from './PromoterLogDialog.vue'

export default {
	name: 'PromoterList',
	components: {
        messageNotifier: MessageNotifier,
		passwordDialog: AccountPasswordDialog,
		statusDialog: PromoterStatusDialog,
        logDialog: PromoterLogDialog,
	},
	data () {
		return {
			loading: false,
            tab: null,
			selectedIndex: -1,
			filters: {
				name: '',
				nric: '',
				mobile_number: '',
				status: '',
				page: 1
			},
            permissions: {
                can_list: false,
                can_add: false,
            },
			statuses: [],
			items: [],
			headers: [],
			pagination: {}
		}
	},
	computed: {
		passwordDialog() {
			return this.$refs.passwordDialog
		},
		statusDialog() {
			return this.$refs.statusDialog
		},
        logDialog() {
            return this.$refs.logDialog
        },
        notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/promoter/getPromoters', this.filters).then((response) => {
				this.items = response.data.items
				this.headers = response.data.headers
				this.pagination = response.data.pagination
				this.statuses = response.data.statuses
				this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		getViewUrl: function(id) {
            return 'promoter/view/' + id
		},
		getEditUrl: function(id) {
            return 'promoter/edit/' + id
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { name: '', nric: '', mobile_number: '', status: '', page: 1}
		},
        changeTab: function() {
            this.filters.status = this.statuses[this.tab].value
            this.get()
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        openCreateDialog: function() {
            if(this.permissions.can_add){
                this.createDialog.open()
            }
        },
		openUpdateDialog: function(index, item) {
            this.selectedIndex = index
            this.updateDialog.url = item.profile_url
			this.updateDialog.object = { first_name: item.first_name, last_name: item.last_name, nric: item.nric, email: item.email }
			this.updateDialog.open()
		},
        openPasswordDialog: function(item) {
            if(item.can_edit_password){
                this.passwordDialog.updateObject({ id: item.id, type: 'admin' })
                this.passwordDialog.open()
            }
        },
        openStatusDialog: function(index, item, status) {
            if(item.can_lock || item.can_approval || item.can_ban){
                this.selectedIndex = index
                this.statusDialog.updateObject({ id: item.id, status: status })
                this.statusDialog.open()
            }
        },
        openLogDialog: function(item) {
            if(item.can_view_logs){
                this.logDialog.updateObject({ id: item.id })
                this.logDialog.open()
            }
        },
        updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
        },
        hasMenu: function(item){
            var hasMenu = item.can_lock || item.can_view || item.can_edit || item.can_ban || item.can_approval
            return hasMenu || item.can_edit_password || item.can_view_logs
        }
	}
}

</script>